html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

* {
  box-sizing: border-box; }
  *:focus {
    outline: none !important; }

body {
  font-family: "Source Sans Pro", sans-serif;
  line-height: normal; }

main[role="main"] {
  flex-grow: 1; }

.breakout {
  margin-left: -1000px;
  margin-right: -1000px;
  padding-left: 1000px;
  padding-right: 1000px;
  background-color: #f9f9f9; }

#root {
  overflow-x: hidden !important;
  min-height: 100vh; }

.wrap {
  margin: 0 auto 40px auto;
  padding: 0 24px; }
  @media (min-width: 768px) {
    .wrap {
      padding: 0 10px; } }

:focus {
  outline: #037fac auto 5px; }

@media (max-width: 767px) {
  main[role="main"] header[role="banner"] + .wrap {
    margin-top: 129px; } }

p {
  margin-bottom: 20px; }

.alpha {
  font-size: 36px; }

.beta {
  font-size: 24px; }

.gamma {
  font-size: 20px; }

.delta {
  font-size: 18px; }

.epsilon {
  font-size: 16px; }

.zeta {
  font-size: 14px; }

.eta {
  font-size: 13px; }

.align-left {
  text-align: left !important; }

.align-right {
  text-align: right !important; }

.bold {
  font-weight: 700; }

.center {
  text-align: center; }

.italic {
  font-style: italic; }

.light {
  font-weight: 300; }

.semibold {
  font-weight: 600; }

.uppercase {
  text-transform: uppercase; }

.capitalise {
  text-transform: capitalize; }

form input {
  font-family: "Source Sans Pro", sans-serif; }
  form input[type="password"], form input[type="text"], form textarea {
    background-color: #ffffff;
    border: solid 1px #dddddd;
    border-radius: 7px;
    font-size: 16px;
    padding: 12px; }

form textarea {
  width: 100%; }

form label {
  display: block; }
  form label .optional-tag {
    font-weight: normal; }

form label, form .error-message {
  font-weight: 600; }

form label, form .error-message, form .form-line {
  margin-bottom: 20px; }

form .error-message {
  color: #de0000;
  margin-left: 28px;
  text-indent: -28px;
  white-space: pre-wrap; }
  form .error-message svg {
    margin-right: 10px; }

form .form-control {
  margin-bottom: 48px; }
  form .form-control.has-error input[type="password"], form .form-control.has-error input[type="text"], form .form-control.has-error textarea {
    background-color: #ffeded;
    border: 2px solid #ff0000;
    color: #de0000; }

.pill {
  border-radius: 12px;
  padding: 5px 15px;
  text-align: center;
  vertical-align: top; }
  .pill.danger {
    background-color: #de3535; }
  .pill.dark {
    background-color: #6e6e6e; }
  .pill.default {
    background-color: #c4c4c4; }
  .pill.default, .pill.warning {
    color: #0b0c0c; }
  .pill.danger, .pill.dark, .pill.success {
    color: #ffffff; }
  .pill.success {
    background-color: #149d4b; }
  .pill.warning {
    background-color: #e7e54b; }

.react-select-container {
  margin-bottom: 40px; }
  .react-select-container button {
    align-self: center; }
  .react-select-container .react-select {
    flex: 1;
    height: 100%;
    max-width: calc(100% - 66px); }
    .react-select-container .react-select .custom-option {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 20px;
      transition: background-color 0.2s ease-in-out; }
      .react-select-container .react-select .custom-option:hover {
        background-color: #f9f9f9; }
      .react-select-container .react-select .custom-option:not(:last-child) {
        border-bottom: 1px solid #dddddd; }
      .react-select-container .react-select .custom-option img {
        border-radius: 50%;
        margin-right: 12px; }
    .react-select-container .react-select .react-select__control {
      border-color: #dddddd;
      border-radius: 0 8px 8px 0;
      box-shadow: none;
      height: 100%; }
    .react-select-container .react-select .react-select__menu-list {
      padding: 0; }
    .react-select-container .react-select .react-select__value-container {
      padding-left: 20px; }
  .react-select-container .react-select-input {
    display: flex;
    height: 58px;
    position: relative; }
    .react-select-container .react-select-input:before {
      align-items: center;
      background-color: #f9f9f9;
      border: 1px solid #dddddd;
      border-radius: 8px 0 0 8px;
      border-right: 0 none;
      content: "";
      display: inline-flex;
      font-size: 32px;
      font-weight: 700;
      height: 56px;
      justify-content: center;
      width: 65px; }
    .react-select-container .react-select-input > svg {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50px / 2); }

.link {
  color: #0600ff;
  cursor: pointer;
  text-decoration: underline; }
  .link svg {
    margin-left: 10px; }

.section-heading {
  margin-bottom: 32px; }

.section-heading-form {
  margin-bottom: 60px; }

@media (max-width: 767px) {
  .mobile-hide {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media (min-width: 768px) {
  .tablet-hide {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media (min-width: 992px) {
  .desktop-hide {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }
