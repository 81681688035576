main[role="main"] {
  transition: margin-left 0.2s ease-in-out; }
  .navigation.closed + main[role="main"] {
    margin-left: 0; }

.navigation {
  background-color: #1e1e1e;
  height: 100vh;
  padding: 0 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  transition: transform 0.2s ease-in-out;
  width: 160px;
  z-index: 111; }
  .navigation.closed {
    transform: translate(-160px, 0); }
  .navigation.open {
    left: 0; }
  .navigation > ul > .navigation-item {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px; }
  .navigation .identity-navigation {
    display: none;
    margin-left: -10px; }
    .navigation .identity-navigation img {
      display: none; }
    .navigation .identity-navigation .details {
      word-break: break-word; }
    .navigation .identity-navigation .dropdown {
      white-space: initial; }
      .navigation .identity-navigation .dropdown .toggle {
        color: #ffffff; }
        .navigation .identity-navigation .dropdown .toggle:after {
          border-top-color: #ffffff;
          right: -10px; }
        .navigation .identity-navigation .dropdown .toggle > div {
          display: flex; }
    .navigation .identity-navigation .items {
      background-color: #1e1e1e;
      border: 0 none;
      box-shadow: initial;
      color: #ffffff;
      left: -10px; }
      .navigation .identity-navigation .items li:hover {
        background-color: rgba(249, 249, 249, 0.05); }
  .navigation .navigation-item {
    color: #ffffff;
    cursor: pointer;
    user-select: none; }
    .navigation .navigation-item.active > div .wrapper {
      background-color: rgba(249, 249, 249, 0.1);
      border-left: 4px solid #ae1fad; }
      .navigation .navigation-item.active > div .wrapper > .title {
        font-weight: 700; }
    .navigation .navigation-item.centre-settings {
      color: rgba(255, 255, 255, 0.6); }
    .navigation .navigation-item.parent {
      margin-bottom: 8px; }
    .navigation .navigation-item svg {
      margin-right: 16px; }
      .navigation .navigation-item svg + .title {
        margin-left: 0; }
    .navigation .navigation-item .title {
      margin-left: 32px; }
    .navigation .navigation-item .wrapper {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
      border-left: 4px solid transparent;
      display: flex;
      padding-top: 16px;
      padding-bottom: 16px;
      transition: all 0.2s ease-in-out; }
      .navigation .navigation-item .wrapper:hover {
        background-color: rgba(249, 249, 249, 0.05);
        border-left: 4px solid rgba(174, 31, 173, 0.7); }
  .navigation .logo {
    cursor: pointer;
    display: block;
    margin: 23px auto 60px;
    max-width: 100%; }
  .navigation .navigation-toggle {
    color: #000000;
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 23px;
    left: calc(100% + 20px); }
    .navigation .navigation-toggle svg {
      margin-right: 15px; }
