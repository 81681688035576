@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

@media (max-width: 767px) {
  header[role="banner"] {
    height: 85px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1; }
    header[role="banner"] h1 {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    header[role="banner"] .identity-navigation {
      display: none; } }

@media (min-width: 768px) {
  header[role="banner"] .wrap {
    padding: 0 10px; } }
