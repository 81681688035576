.form-progress-indicator {
  padding-top: 35px;
  position: relative;
  top: -60px; }
  .form-progress-indicator > span:first-child {
    color: rgba(0, 0, 0, 0.59); }
  .form-progress-indicator .progress-bar {
    background-color: #ae1fad;
    position: absolute;
    left: 0;
    height: 100%; }
  .form-progress-indicator .progress-bar-container {
    background-color: #dddddd;
    content: "";
    height: 8px;
    margin: 25px 0 40px;
    overflow: hidden;
    position: relative; }
