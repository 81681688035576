.text-summary-container .summary-heading {
  font-size: 24px;
  margin-bottom: 32px; }

.text-summary-container .summary-action {
  font-size: 18px;
  color: #6e6e6e; }

.text-summary-container .statements {
  margin-left: 25px; }
  .text-summary-container .statements li {
    list-style-type: none;
    margin-bottom: 5px;
    position: relative;
    font-weight: bold;
    padding-left: 5px; }
    .text-summary-container .statements li i {
      position: absolute;
      left: -25px;
      color: #9d9d9d;
      top: 50%;
      transform: translateY(-50%); }
  .text-summary-container .statements li:not(:first-child) {
    margin-top: 24px; }
  .text-summary-container .statements li.negative:before {
    content: "-"; }
