@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

@media screen and (min-width: 768px) {
  .rating-bar-container {
    max-width: 615px; } }

.rating-bar-container .title {
  font-size: 28px; }

.rating-bar-container p {
  margin-bottom: 15px; }

.rating-bar-container .potential {
  display: flex;
  flex: 1 1 0;
  align-items: stretch;
  height: 12px;
  padding-left: 0;
  margin: 0px -2px; }
  .rating-bar-container .potential li {
    display: flex;
    flex: 1 1 0;
    background-color: #dddddd;
    margin: 0 1px; }
    .rating-bar-container .potential li.active {
      background-color: #ae1fad; }
    .rating-bar-container .potential li:first-child {
      border-radius: 6px 0 0 6px; }
    .rating-bar-container .potential li:last-child {
      border-radius: 0 6px 6px 0; }

.rating-bar-container .score h3 {
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  margin-bottom: 8px;
  color: #6e6e6e; }

.rating-bar-container .score h3 span {
  font-size: 32px;
  color: #000000;
  vertical-align: bottom; }

@media (min-width: 768px) {
  .rating-bar-container .rating-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-top: 42px; }
  .rating-bar-container .score {
    display: flex;
    padding-left: 30px; }
  .rating-bar-container .score h3 {
    margin-bottom: 0; } }
