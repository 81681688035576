@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

@media (max-width: 767px) {
  .error .hero .column-one {
    width: calc(2em + ((100% - 3em) / 4 * 3)); }
  .error .hero .column-two {
    margin-left: calc(((100% - 3em) / 4 * 0.2));
    text-align: right;
    width: calc(((100% - 3em) / 4 * 0.8)); } }

@media (min-width: 768px) {
  .error .hero .column-one {
    width: calc(5em + ((100% - 7em) / 8 * 6)); }
  .error .hero .column-two {
    margin-left: calc(((100% - 7em) / 8 * 0.5));
    text-align: right;
    width: calc(1em + ((100% - 7em) / 8 * 1.5)); } }

@media (min-width: 992px) {
  .error .hero .column-one {
    width: calc(5em + ((100% - 11em) / 12 * 6)); }
  .error .hero .column-two {
    margin-left: calc(((100% - 11em) / 12 * 1));
    text-align: right;
    width: calc(4em + ((100% - 11em) / 12 * 5)); } }
