@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

.report-progress-bar {
  position: absolute;
  height: 8px;
  width: 100%;
  background: #d9d8da;
  top: 80px;
  left: 0; }
  @media (max-width: 767px) {
    .report-progress-bar {
      height: 10px; } }

.filler {
  background: #088a00;
  height: 100%;
  transition: width .2s ease-in;
  border-radius: 0 5px 5px 0; }
