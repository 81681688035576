.navigation.closed + main[role="main"] .cookie-consent {
  left: 0; }

.navigation.open + main[role="main"] .cookie-consent {
  transform: translate(160px, 0); }

.cookie-consent {
  align-items: baseline;
  background: #1e1e1e;
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: transform 0.2s ease-in-out;
  width: 100%;
  z-index: 999; }
  .cookie-consent a {
    color: #ffffff; }
  .cookie-consent .content {
    margin: 23px; }
