.reports .report {
  background-color: #f9f9f9;
  margin-bottom: 40px;
  padding: 40px; }
  .reports .report h1, .reports .report h2, .reports .report h3 {
    margin-bottom: 20px; }

.style-report-container {
  position: relative; }
  .style-report-container .rating-bar-container {
    margin-bottom: 100px; }
