@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

@media (min-width: 992px) {
  .generic-form {
    width: calc(7em + ((100% - 11em) / 12 * 8)); } }

.generic-form input {
  width: 100%; }
