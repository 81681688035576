@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

.summary-page .bg-alabaster {
  background-color: #f9f9f9; }

.summary-page .strength-row {
  display: flex; }
  .summary-page .strength-row .column-one {
    position: relative; }
  @media (max-width: 767px) {
    .summary-page .strength-row > .column-one {
      display: none; }
    .summary-page .strength-row > .column-two {
      width: calc(3em + (100% - 3em)); } }
  @media (min-width: 768px) {
    .summary-page .strength-row .column-one {
      width: calc(1em + ((100% - 7em) / 8 * 1.5));
      margin-right: calc(((100% - 7em) / 8 * 0.5));
      text-align: right; }
    .summary-page .strength-row .column-two {
      width: calc(5em + ((100% - 7em) / 8 * 6)); } }
  @media (min-width: 992px) {
    .summary-page .strength-row .column-one {
      width: calc(1em + ((100% - 11em) / 12 * 2));
      margin-right: calc(((100% - 11em) / 12 * 0.4));
      position: relative; }
    .summary-page .strength-row .column-two {
      width: calc(9em + ((100% - 11em) / 12 * 10)); } }

.summary-page .strength-report {
  margin-top: 88px; }
  .summary-page .strength-report h1.title {
    font-size: 28px; }
  .summary-page .strength-report .strength-row .category-row .text-summary-container {
    margin: 32px 0;
    padding-right: 5px; }
  @media (max-width: 767px) {
    .summary-page .strength-report {
      margin-left: -100%;
      margin-right: -100%;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw); }
      .summary-page .strength-report .strength-row .category-row {
        flex-direction: column; }
      .summary-page .strength-report .strength-row .category-row > .column-one {
        padding-bottom: 15px;
        width: calc(3em + (100% - 3em));
        margin-right: 0;
        text-align: center; }
      .summary-page .strength-report .strength-row .category-row > .column-two {
        width: calc(3em + (100% - 3em)); } }

@media (max-width: 767px) {
  .summary-page .competency-row > .category-row > .column-two {
    width: calc(3em + (100% - 3em)); } }

div[data-accordion-component="Accordion"] button {
  pointer-events: none; }

div[data-accordion-component="Accordion"] button[data-testid="See all"] {
  background-color: #ffffff; }

div[data-accordion-component="Accordion"] button[data-testid="Hide"] {
  background-color: #eeeeee; }

div[data-accordion-component="Accordion"] div[aria-expanded="false"] button[data-testid="Hide"] {
  display: none; }

div[data-accordion-component="Accordion"] div[aria-expanded="true"] button[data-testid="See all"] {
  display: none; }
