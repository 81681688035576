.button {
  border: 0 none;
  border-radius: 7px;
  cursor: pointer;
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: bold;
  min-width: 104px;
  padding: 11px 16px;
  text-align: center;
  text-decoration: none;
  transition: all ease-in-out 0.2s;
  user-select: none; }
  .button[disabled] {
    cursor: not-allowed;
    opacity: 0.4; }
  .button.icon-right svg {
    margin-left: 10px;
    margin-right: 0; }
  .button:not(.icon-right) svg {
    margin-right: 10px; }
  .button.primary {
    background-color: #ae1fad;
    color: #ffffff; }
    .button.primary:hover {
      background-color: #a11da0; }
  .button.quatenary {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.37); }
  .button.quatenary, .button.quinary, .button.senary {
    background-color: transparent;
    text-decoration: underline;
    min-width: 0px;
    padding-left: 0px;
    padding-right: 0px; }
    .button.quatenary:hover, .button.quinary:hover, .button.senary:hover {
      text-decoration: none; }
  .button.quinary {
    color: #ae1fad;
    font-size: 14px; }
  .button.senary {
    color: #bf3f1d;
    font-size: 14px; }
  .button.secondary {
    background-color: #dddddd;
    color: #000000; }
    .button.secondary:hover {
      background-color: #d5d5d5; }
  .button.tertiary {
    background-color: #037fac;
    color: #ffffff; }
    .button.tertiary:hover {
      background-color: #03749d; }
