@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

.overview-page {
  position: relative; }

.title-overview {
  line-height: normal; }

.text-right {
  text-align: right; }

.breakout .wrap {
  padding-top: 48px;
  padding-bottom: 48px; }

.summary-example.breakout .wrap {
  padding-top: 16px;
  padding-bottom: 16px; }

ul.bullets {
  list-style: none;
  margin-left: 25px; }
  ul.bullets li {
    list-style-type: none;
    margin-bottom: 5px;
    position: relative;
    padding-left: 5px; }
    ul.bullets li i {
      position: absolute;
      left: -25px;
      color: #9d9d9d;
      top: 2px; }
  ul.bullets li > span.competency-title:first-of-type {
    margin-left: 5px; }
  ul.bullets li > span.competency-title:not(:nth-last-child(-n+2))::after {
    content: ", "; }
  ul.bullets li > span.competency-title:last-child::before {
    content: " and "; }

.row {
  display: inline-block;
  position: relative;
  width: 100%; }
  .row [class*="col-"] {
    float: left; }
  @media (max-width: 767px) {
    .row .col-3 {
      width: calc(3em + (100% - 3em)); }
    .row .col-4 {
      width: calc(3em + (100% - 3em)); }
    .row .col-6 {
      width: calc(1em + ((100% - 3em) / 4 * 2)); }
    .row .col-9 {
      width: calc(3em + (100% - 3em)); } }
  @media (min-width: 768px) {
    .row .col-3 {
      width: calc(3em + ((100% - 7em) / 8 * 4)); }
    .row .col-4 {
      width: calc(3em + ((100% - 7em) / 8 * 4)); }
    .row .col-6 {
      width: calc(1em + ((100% - 7em) / 8 * 2)); }
    .row .col-9 {
      width: calc(3em + ((100% - 7em) / 8 * 4)); } }
  @media (min-width: 992px) {
    .row .col-3 {
      width: calc(2em + ((100% - 11em) / 12 * 3)); }
    .row .col-4 {
      width: calc(4em + ((100% - 11em) / 12 * 5)); }
    .row .col-6 {
      width: calc(5em + ((100% - 11em) / 12 * 6)); }
    .row .col-9 {
      width: calc(8em + ((100% - 11em) / 12 * 9)); } }

.category-row {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .category-row .column-one {
      width: calc(((100% - 3em) / 4 * 1));
      margin-right: calc(((100% - 3em) / 4 * 0.5)); }
    .category-row .column-two {
      width: calc(2em + ((100% - 3em) / 4 * 3)); } }
  @media (min-width: 768px) {
    .category-row .column-one {
      width: calc(1em + ((100% - 7em) / 8 * 1.5));
      margin-right: calc(((100% - 7em) / 8 * 0.5)); }
    .category-row .column-two {
      width: calc(5em + ((100% - 7em) / 8 * 6)); } }
  @media (min-width: 992px) {
    .category-row .column-one {
      width: calc(1em + ((100% - 11em) / 12 * 2));
      margin-right: calc(((100% - 11em) / 12 * 0.5)); }
    .category-row .column-two {
      width: calc(9em + ((100% - 11em) / 12 * 10)); } }

.flex-container {
  display: flex;
  align-items: center; }
  .flex-container > div {
    display: flex; }
  .flex-container > div.flex-grow {
    flex-grow: 1;
    position: relative; }

.rating-explanation {
  color: #6e6e6e; }
  .rating-explanation .flex-grow > div {
    margin-left: 30px;
    margin-right: 30px;
    width: 100%;
    position: relative; }
  .rating-explanation .arrow.double-headed {
    position: absolute;
    border-bottom: 4px solid #6e6e6e;
    width: 100%; }
  @media screen and (min-width: 768px) {
    .rating-explanation {
      max-width: 540px; } }

.arrow-head {
  border: solid #6e6e6e;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;
  position: absolute; }
  .arrow-head.left {
    transform-origin: left;
    transform: translate(98%, -70%) rotate(135deg); }
  .arrow-head.right {
    right: 0;
    transform-origin: left;
    transform: rotate(-45deg); }
