@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

@media (min-width: 768px) {
  .navigation.open + main[role="main"] {
    margin-left: 160px; } }

@media (max-width: 767px) {
  .navigation .identity-navigation {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px; } }

@media (min-width: 768px) {
  .navigation .navigation-item.centre-settings {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px; }
  .navigation .navigation-toggle {
    display: none; } }
