.breadcrumb {
  color: #6e6e6e;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700; }
  .breadcrumb svg {
    margin-right: 5px;
    position: relative;
    top: 1px; }

.breadcrumbs {
  margin-bottom: 40px; }
