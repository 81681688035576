.loading-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background-color: #f9f9f9; }
  .loading-block img {
    margin-top: 65px;
    margin-bottom: 55px; }
  .loading-block .spinner-text {
    margin-bottom: 65px; }

.loading-block.fullscreen {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  border-radius: 0;
  background-color: rgba(249, 249, 249, 0.7); }
  .loading-block.fullscreen .content-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
