@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

@media (max-width: 767px) {
  .groups-list header {
    align-items: flex-start; }
  .groups-list .groups-sorting > span {
    display: block; }
  .groups-list .groups-sorting .desktop-text, .groups-list .groups-status-toggle .desktop-text {
    display: none; }
  .groups-list .groups-status-toggle {
    text-transform: capitalize; } }

@media (min-width: 768px) {
  .groups-list header {
    align-items: center; }
  .groups-list .groups-sorting .sort-toggle:not(:last-child) {
    margin-right: 40px; } }
