.dropdown {
  position: relative;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none; }
  .dropdown.open .toggle:after {
    transform: rotate(180deg); }
  .dropdown.x-position-left .items {
    right: -20px; }
  .dropdown.x-position-right .items, .dropdown.x-position-undefined .items {
    left: 0; }
  .dropdown.y-position-bottom .items, .dropdown.y-position-undefined .items {
    top: calc(100% + 5px); }
  .dropdown.y-position-top .items {
    bottom: calc(100% + 5px); }
  .dropdown .items {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 #dddddd;
    position: absolute;
    z-index: 1; }
    .dropdown .items li {
      display: block;
      padding: 15px;
      transition: background-color 0.2s ease-in-out; }
      .dropdown .items li:hover {
        background-color: #f9f9f9; }
      .dropdown .items li:not(:last-child) {
        border-bottom: 1px solid #dddddd; }
    .dropdown .items span {
      display: block;
      margin: -15px;
      padding: 15px; }
    .dropdown .items svg {
      margin-right: 10px; }
  .dropdown .items, .dropdown .toggle {
    cursor: pointer; }
  .dropdown .toggle {
    color: #ae1fad;
    position: relative; }
    .dropdown .toggle:after {
      content: "";
      height: 0;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -20px;
      width: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #ae1fad; }
