.identity-navigation .dropdown .toggle {
  color: #000000; }
  .identity-navigation .dropdown .toggle:after {
    border-top-color: #000000; }

.identity-navigation .details {
  display: inline-block;
  position: relative;
  vertical-align: middle; }

.identity-navigation .details, .identity-navigation img {
  display: inline-block;
  vertical-align: middle; }

.identity-navigation img {
  border-radius: 50%;
  margin-right: 10px; }
