.radio-group input[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.radio-group .radio-label {
  margin-left: 10px;
  font-weight: normal; }
